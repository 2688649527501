<template>
  <div style="height: 100vh">
    <van-sticky>
      <div class="header-title" style="padding: 10px">
        <van-row gutter="10">
          <van-col span="10" class="" @click="onClickBack">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
              style="height: 25px"
            />
            <span
              style="
                font-size: 25px;
                color: white;
                font-weight: bold;
                margin-left: 0.1rem;
              "
              >消息通知</span
            >
          </van-col>
          <!-- <van-col span="14" style="text-align: right" @click="onIssueClick">
            <svg-icon :data_iconName="'edit'" :className="'edit-icon'" />
          </van-col> -->
        </van-row>
        <van-row class="tabs">
          <van-col
            span="6"
            v-for="(item, index) in tabs"
            :key="index"
            :class="[item.type == currentType ? 'tab-item-active' : 'tab-item']"
            @click="onTabClick(item, index)"
            >{{ item.text }}</van-col
          >
        </van-row>
      </div>
    </van-sticky>
    <div class="message-container" v-if="currentTabIndex < 2">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="refreshNotifications(currentType)"
        style="padding-bottom: 2rem"
      >
        <van-list
          v-model="listLoading"
          :finished="listFinished"
          :finished-text="notifications.length > 0 ? '没有更多了' : ''"
          @load="loadNotificationPage"
        >
          <card
            v-for="(item, index) in notifications"
            :key="index"
            class="message-item"
            @click.native="onMessageClick(item)"
            :style="{ background: item.relation.is_read ? '#f1f1f1' : '#fff' }"
          >
            <template #content>
              <van-row>
                <van-col span="14" class="message-title-col">
                  <span
                    v-html="item.notification.title"
                    :class="
                      item.relation.is_read
                        ? 'tab-item-title-isread'
                        : 'tab-item-title'
                    "
                  ></span>
                </van-col>
                <van-col span="10">
                  <span style="font-size: 12px; color: grey">{{
                    item.notification.created_at
                  }}</span>
                </van-col>
              </van-row>
              <div v-if="!item.relation.is_read" class="dot" />
            </template>
          </card>
          <van-empty description="没有通知" v-if="notifications.length === 0" />
        </van-list>
      </van-pull-refresh>
    </div>
    <div v-if="currentTabIndex == 2" class="report-container">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="refreshReport"
        style="padding-bottom: 2rem"
      >
        <van-list
          v-model="listLoading"
          :finished="listFinished"
          :finished-text="reports.length > 0 ? '没有更多了' : ''"
          @load="loadReportPage"
        >
          <card>
            <template #content>
              <van-cell
                is-link
                title="报告类型"
                @click="showReportTypeSelect = true"
              />
            </template>
          </card>
          <van-empty description="暂无报告" v-if="reports.length === 0" />
          <card
            v-for="(item, index) in reports"
            :key="index"
            @click.native="toReportDetail(item.id)"
          >
            <template #content>
              <van-row>
                <van-col span="14">
                  <van-row style="height: 0.4rem">
                    <div style="line-height: 15px">
                      <span class="tab-item-title">{{
                        item.report_type_text
                      }}</span>
                    </div>
                  </van-row>
                  <van-row style="height: 0.4rem; margin-top: 0.2rem">
                    <span class="message-content">
                      {{ item.report_type == 0 ? "今日" : "本周" }}带团数：{{
                        item.lists_count
                      }}个，共计{{ item.tourists_count }}人/次
                    </span>
                  </van-row>
                </van-col>
                <van-col span="10" style="text-align: right">
                  <van-row style="height: 0.4rem">
                    <div>
                      <span class="message-content">{{
                        item.submit_time
                      }}</span>
                    </div>
                  </van-row>
                  <van-row style="height: 0.4rem; margin-top: 0.2rem">
                    <span class="message-content">
                      紧急情况上报：{{
                        item.emergency_reports_count == null
                          ? 0
                          : item.emergency_reports_count
                      }}
                    </span>
                  </van-row>
                </van-col>
              </van-row>
            </template>
          </card>
        </van-list>
      </van-pull-refresh>
      <div class="action_bg">
        <van-button
          type="info"
          class="action_btn"
          color="#395EE8"
          @click="toReportEdit()"
          >提交报告</van-button
        >
      </div>
    </div>
    <div class="message-container" v-if="currentTabIndex == 3">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="refreshGift"
        style="padding-bottom: 2rem"
      >
        <van-list
          v-model="listLoading"
          :finished="listFinished"
          :finished-text="jiangbuList.length > 0 ? '没有更多了' : ''"
          @load="loadJianbuPage"
        >
          <card
            v-for="(item, index) in jiangbuList"
            :key="index"
            class="message-item"
            @click.native="onMessageClicktwo(item)"
            :style="{ background: '#fff' }"
          >
            <template #content>
              <van-row>
                <van-col span="14" class="message-title-col">
                  <span
                    v-html="item.jiang_bu.title"
                    :class="item.is_read ? 'tab-item-title' : 'tab-item-title'"
                  ></span>
                </van-col>
                <van-col span="10">
                  <span style="font-size: 12px; color: grey">{{
                    item.created_at
                  }}</span>
                </van-col>
              </van-row>
              <!-- <div  v-if="!item.is_read || !item.is_gon_shi_read" class="dot" /> -->
              <div v-if="!item.is_read" class="dot" />
            </template>
          </card>
          <van-empty description="暂无数据" v-if="jiangbuList.length === 0" />
        </van-list>
      </van-pull-refresh>
      <!-- <div class="action_bg">
        <van-button
          type="info"
          class="action_btn"
          color="#395EE8"
          @click="toJiangbuList()"
          >申请</van-button
        >
      </div> -->
    </div>
    <van-action-sheet
      v-model="showReportTypeSelect"
      :actions="reportTypeActions"
      @select="onReportTypeSelect"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import card from "@/views/components/Card";
import api from "@/services/apis.js";
export default {
  components: {
    card,
  },
  data() {
    return {
      tabs: [
        {
          text: "出团",
          type: 3,
        },
        {
          text: "系统",
          type: "1,2,5",
        },
        {
          text: "报告",
          type: "report",
        },
        {
          text: "奖补",
          type: 6,
        },
      ],
      currentType: 3,
      currentTabIndex: 0,
      isLoading: false,
      defualtReportType: 0,
      showReportTypeSelect: false,
      reportTypeActions: [
        { name: "日报", value: 0 },
        { name: "周报", value: 1 },
      ],
      currentReportType: 0,
      listLoading: false,
      paging: {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
        next_page: null,
      },

      // 奖补相关
      jiangbuList: [],
      notifications: [],
    };
  },
  created() {
    if (this.$route.query.tabIndex != undefined) {
      this.currentType = this.tabs[this.$route.query.tabIndex].type;
      this.currentTabIndex = this.$route.query.tabIndex;
    } else {
      this.currentType = this.tabs[0].type;
      this.currentTabIndex = 0;
    }
    this.refreshNotifications(this.currentType);
    this.$store.dispatch("report/getAllReports", { type: 0 });

    if (this.currentTabIndex == 3) {
      this.refreshGift();
    }
  },
  computed: {
    // ...mapGetters("xiaoxi", {
    //   notifications: "getAllNotifications",
    // }),
    ...mapGetters("report", {
      reports: "getAllReports",
    }),
    listFinished() {
      return this.paging.next_page == null;
    },
  },
  methods: {
    onClickBack() {
      this.$router.push("/home");
    },
    onMessageClick(item) {
      this.$router.push({
        path: `/xiaoxi/info/${item.relation.id}`,
        query: { tabIndex: this.currentTabIndex },
      });
    },
    onMessageClicktwo(item) {
      this.$router.push({
        path: `/xiaoxi/jiangbuinfo`,
        query: { id: item.jiang_bu.id },
      });
    },
    toReportDetail(id) {
      this.$router.push({
        path: `/xiaoxi/report/${id}`,
        query: { tabIndex: this.currentTabIndex },
      });
    },
    toReportEdit() {
      this.$router.push("/xiaoxi/editReport");
    },
    // 跳转可以申请的奖补列表
    toJiangbuList() {
      this.$router.push("/xiaoxi/jiangbuList");
    },
    onTabClick(item, index) {
      console.log(index);
      this.currentType = item.type;
      if (index < 2) {
        this.refreshNotifications(this.currentType);
      } else if (index == 3) {
        this.refreshGift(this.currentType);
      } else {
        this.$store.dispatch("report/getAllReports", { type: 0 });
      }
      this.currentTabIndex = index;
    },
    fillPaging(res) {
      this.paging.total_count = res.total_count;
      this.paging.total_pages = res.total_pages;
      this.paging.current_page = res.current_page;
      this.paging.next_page = res.next_page;
    },
    async refreshNotifications() {
      const _self = this;
      let zhtype = this.currentType;
      if (this.currentType === 6) {
        zhtype = 3;
      }
      this.notifications = [];
      const res = await api.get_notifications({
        notification_type: zhtype,
        page: _self.paging.next_page,
      });
      console.log("---", res);
      this.notifications = [...this.notifications, ...res.data.data];
      _self.isLoading = false;
      _self.fillPaging(res);
      // this.$store
      //   .dispatch("xiaoxi/getAllNotificationsByType", {
      //     type: zhtype,
      //     page: _self.paging.next_page,
      //   })
      //   .then((res) => {
      //     console.log("000000", res);
      //     _self.isLoading = false;
      //     _self.fillPaging(res);
      //   });
    },

    // 获取奖补数据
    async refreshGift() {
      const _self = this;
      let params = {
        // status:["approving","approve_pass","approve_deny"],
        // status:["approving","approve_pass","approve_deny"],
      };
      let res = await api.get_jiangbu_list(params);
      console.log("zzz", res.data);
      if (res.data.status === 200) {
        this.jiangbuList = res.data.data;
        _self.isLoading = false;
        _self.fillPagingtwo(res);
      }
    },
    fillPagingtwo(res) {
      console.log("----res", res);
      this.paging.total_count = res.data.page.total_count;
      this.paging.total_pages = res.data.page.total_pages;
      this.paging.next_page = res.data.page.next_page;
      this.paging.current_page = res.data.page.current_page;
    },

    onReportTypeSelect(data) {
      const _self = this;
      this.$store
        .dispatch("report/getAllReports", { type: data.value })
        .then((res) => {
          if (res.status == 200) {
            _self.isLoading = false;
            _self.fillPaging(res);
          }
        });
      this.currentReportType = data.value;
      this.showReportTypeSelect = false;
    },
    refreshReport() {
      const _self = this;
      this.$store
        .dispatch("report/getAllReports", { type: this.currentReportType })
        .then((data) => {
          if (data) {
            _self.isLoading = false;
            _self.fillPaging(data);
          }
        });
    },
    loadNotificationPage() {
      const _self = this;
      if (this.paging.next_page != null) {
        this.$store
          .dispatch("xiaoxi/getAllNotificationsByType", {
            type: _self.currentType,
            page: _self.paging.next_page,
          })
          .then((res) => {
            if (res.status === 200) {
              _self.listLoading = false;
              _self.fillPagingtwo(res.data);
            }
          });
      }
    },
    loadReportPage() {
      const _self = this;
      if (this.paging.next_page != null) {
        this.$store
          .dispatch("report/getAllReports", {
            type: this.currentReportType,
            page: this.paging.next_page,
          })
          .then((res) => {
            if (res.status === 200) {
              _self.listLoading = false;
              _self.fillPaging(res);
              _self.$store.dispatch("report/syncReports", res.data);
            }
          });
      }
    },
    loadJianbuPage() {
      const _self = this;
      if (this.paging.next_page != null) {
        let params = {
          status: ["approving", "approve_pass", "approve_deny"],
          page: this.paging.next_page,
        };
        api.get_jiangbu_list(params).then((res) => {
          if (res.data.code === 200) {
            res.data.data.forEach((ele) => {
              this.jiangbuList.push(ele);
            });
            _self.isLoading = false;
            _self.fillPagingtwo(res.data);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.message-container {
  padding: 0 0.4rem 2rem 0.4rem;
  margin-top: 0.2rem;
  height: 80vh;
  overflow-y: scroll;
}
.report-container {
  margin-top: 0.2rem;
  padding: 0.2rem 0.4rem 2rem 0.4rem;
  height: 80vh;
  overflow-y: scroll;
}
.message-item {
  min-height: 1rem;
  width: 100%;
  border-radius: 15px;
  line-height: 0.6rem;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0.3rem 0.2rem 0.2rem 0.3rem;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
  padding-top: 10px;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-size: 0.3rem;
}
.message-title-col {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 0.1rem;
}
.message-date {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 10px;
  color: #666;
}
.message-content {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipse;
  font-size: 12px;
  color: grey;
  display: block;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
  background: #6c94f6;
  border-bottom-left-radius: 1rem;
}
.tab-item {
  opacity: 0.47;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-active {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-title {
  font-weight: bold;
  font-size: 12px;
}
.tab-item-title-isread {
  font-weight: 600;
  font-size: 12px;
  color: #808080;
}
.action_btn {
  position: fixed;
  bottom: 10px;
  border-radius: 25px;
  width: 95%;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
}
.van-sticky--fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-image: linear-gradient(
    179deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
}
.dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #f04134;
  border-radius: 50%;
  top: 0.2rem;
  right: 0.1rem;
}
</style>